import React from 'react'
import { Link } from 'gatsby'

import RightChevron from '../SVG/RightChevron'

const Primary = ({ services }) => {
  return (
    <div className="mb-24 lg:mb-40 leading-tight">
      <div className="lg:flex justify-between items-center -mb-4 lg:-mb-0 lg:-ml-12">
        <h3 className="hidden lg:block heading-xl lg:w-1/3 lg:ml-12">
          Our Services
        </h3>
        {services.map((item, index) => {
          return (
            <div
              className="button button--white button--shadow lg:w-1/3 lg:ml-12"
              key={index}
            >
              <Link
                className="button__inner lg:text-2xl font-medium"
                to={`/${item.node.uid}`}
              >
                <div className="flex items-end leading-normal lg:leading-tight">
                  <img
                    className="block w-8 h-8 lg:w-10 lg:h-10 mr-4 lg:mr-7 inline-block"
                    src={item.node.data.icon.url}
                    alt={item.node.data.icon.alt}
                  />
                  <span className="block">{item.node.data.heading}</span>
                </div>
                <RightChevron />
              </Link>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default Primary
