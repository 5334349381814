import React from 'react'
import axios from 'axios'
import { withFormik, Form, Field } from 'formik'
import posed, { PoseGroup } from 'react-pose'

import RightChevron from '../../SVG/RightChevron'

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

/** constants */
const formName = 'contactFooter'
const wrapperClass = ''
const msgSuccess = 'Thanks, we’ll be in touch soon.'
const msgError = 'There was an error submitting the form, please try again.'

const Success = () => (
  <div className={wrapperClass}>
    {/* Success Message */}
    <div className="mt-2">{msgSuccess}</div>
  </div>
)

class FormWithError extends React.Component {
  render() {
    const { isSubmitting, errors, isValid } = this.props

    return (
      <Form
        name={formName}
        className={wrapperClass}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <input type="hidden" name="form-name" value={formName} />
        {/* Names */}
        <div className="lg:flex lg:-ml-12">
          <Field
            validate={this.validate}
            id="firstName"
            name="firstName"
            type="text"
            className="w-full mb-10 lg:mb-6 lg:w-1/2 lg:ml-12 font-medium py-3 bg-transparent border-b border-white"
            placeholder="First Name"
            required
          />
          <Field
            validate={this.validate}
            id="lastName"
            name="lastName"
            type="text"
            className="w-full mb-10 lg:mb-6 lg:w-1/2 lg:ml-12 font-medium py-3 bg-transparent border-b border-white"
            placeholder="Last Name"
            required
          />
        </div>
        {/* Email & Phone */}
        <div className="lg:flex lg:-ml-12">
          <Field
            validate={this.validate}
            id="email"
            name="email"
            type="email"
            className="w-full mb-10 lg:mb-6 lg:w-1/2 lg:ml-12 font-medium py-3 bg-transparent border-b border-white"
            placeholder="Email"
            required
          />
          <Field
            validate={this.validate}
            id="phone"
            name="phone"
            type="text"
            className="w-full mb-10 lg:mb-6 lg:w-1/2 lg:ml-12 font-medium py-3 bg-transparent border-b border-white"
            placeholder="Phone Number"
            required
          />
        </div>
        {/* Message */}
        <Message key="Message" />
        {/* <PoseGroup>{isValid && <Message key="Message" />}</PoseGroup> */}

        {/* Submit Btn & Error Message */}
        <div className="mt-15 mb-24 lg:my-3 flex lg:justify-end">
          <button
            type="submit"
            disabled={isSubmitting}
            className="button button--blue border"
          >
            Submit
            <RightChevron className="ml-8" />
          </button>
          {/* Error Message */}
          {errors && errors.showMessage && (
            <div className="mt-4">{errors.showMessage}</div>
          )}
          {errors && errors.submit && <div className="mt-4">{msgError}</div>}
        </div>
      </Form>
    )
  }
}

const MessageField = React.forwardRef((props, ref) => (
  <div ref={ref} {...props}>
    <Field
      className={`w-full mb-10 lg:mb-6 py-4 h-14 font-medium bg-transparent border-b border-white`}
      name="message"
      component="textarea"
      placeholder="Message"
    />
  </div>
))

const Message = posed(MessageField)({
  enter: {
    opacity: 1,
  },
  exit: {
    opacity: 0,
    transition: {
      default: { duration: 0 },
    },
  },
})

const DefaultForm = props => {
  const { status } = props
  if (status && status.success) {
    return <Success />
  } else {
    return <FormWithError {...props} />
  }
}

const FormikForm = withFormik({
  mapPropsToValues: values => ({
    firstName: values.firstName || '',
    lastName: values.lastName || '',
    email: values.email || '',
    phone: values.phone || '',
    message: values.message || '',
  }),
  validate: values => {
    if (
      values.firstName !== '' ||
      values.lastName !== '' ||
      values.email !== '' ||
      values.phone !== ''
    ) {
      return true
    }
  },
  handleSubmit: async (
    form,
    { resetForm, setErrors, setStatus, setSubmitting }
  ) => {
    try {
      await axios.post(
        '?no-cache=1',
        encode({ 'form-name': formName, ...form }),
        { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
      )
      resetForm()
      setStatus({ success: true })
    } catch (error) {
      // Set errors.submit to TRUE
      if (error.response) {
        console.log(error.response)
      } else {
        console.log(error)
      }
      setErrors({
        submit: true,
      })
      // Reset error.submit to FALSE
      setTimeout(() => {
        setErrors({ submit: false })
      }, 5000)
    } finally {
      setSubmitting(false)
    }
  },
})(DefaultForm)

export default FormikForm
