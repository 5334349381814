import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../Header'
import Footer from '../Footer'

import '../../styles/index.css'

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navIsOpen: false,
    }
  }

  noScroll = () => {
    document.documentElement.classList.add('no-scroll')
    document.body.classList.add('no-scroll')
  }

  resumeScroll = () => {
    document.documentElement.classList.remove('no-scroll')
    document.body.classList.remove('no-scroll')
  }

  componentDidMount = () => {
    let vh = window.innerHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)

    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01
      document.documentElement.style.setProperty('--vh', `${vh}px`)
      if (window.innerWidth >= 990) {
        this.resumeScroll()
      }
    })
  }

  toggleNav = () => {
    this.setState({
      navIsOpen: !this.state.navIsOpen,
    })

    if (this.state.navIsOpen) {
      this.resumeScroll()
    } else {
      this.noScroll()
    }
  }

  render() {
    const { children, location } = this.props

    return (
      <StaticQuery
        query={graphql`
          query SiteTitleQuery {
            site {
              siteMetadata {
                title
              }
            }
          }
        `}
        render={data => (
          <div className="font-sans text-blue text-base antialiased leading-normal overflow-hidden">
            <Header
              toggleNav={this.toggleNav}
              navIsOpen={this.state.navIsOpen}
              location={location}
            />
            {children}
            <Footer location={location} />
          </div>
        )}
      />
    )
  }
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
