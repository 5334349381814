import React from 'react'

import Form from '../Footer/Form'

const Secondary = ({ global }) => {
  return (
    <div className="pb-24 lg:pb-40">
      <div className="lg:flex">
        <div className="lg:w-1/3 pr-12 pb-12">
          <h3 className="heading-xl">{global.heading}</h3>
        </div>
      </div>
      <div className="flex flex-col lg:flex-row lg:-ml-12">
        <div className="lg:w-2/3 lg:pl-12 lg:order-2 -mt-3">
          <Form />
        </div>
        <div
          className="lg:w-1/3 lg:pl-12 lg:order-1 font-medium leading-relaxed"
          dangerouslySetInnerHTML={{ __html: global.address.html }}
        />
      </div>
    </div>
  )
}

export default Secondary
