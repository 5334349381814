import React from 'react'

import Menu from '../Menu/footer'

const Tertiary = ({ site }) => {
  return (
    <div className="flex flex-col lg:flex-row justify-between text-xs font-medium">
      <Menu />
      <div className="lg:order-1">
        © copyright {new Date().getFullYear()} {site.title}
      </div>
    </div>
  )
}

export default Tertiary
