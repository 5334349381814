import React from 'react'
import { Link } from 'gatsby'

import Logo from '../SVG/Logo'
import Logomark from '../SVG/Logomark'
import Menu from '../Menu'

class Header extends React.Component {
  render() {
    const { toggleNav, navIsOpen, location } = this.props
    return (
      <nav
        className={`lg:py-11${
          location.pathname === '/about' ||
          location.pathname === '/about/' ||
          (location.pathname.split('/').length > 2 &&
            location.pathname.split('/')[1] === 'case-studies' &&
            location.pathname.split('/')[2] !== '')
            ? ' bg-blue text-white'
            : ''
        }`}
      >
        <div className="pb-15 pt-5 lg:py-0">
          <div className="wrapper lg:relative">
            <div className="flex justify-between items-center">
              <Link to="/">
                <Logo className="w-32 lg:w-42 block" />
              </Link>
              <Menu
                location={location.pathname}
                className="hidden lg:flex items-center justify-between"
              />

              <button
                className={`lg:hidden hamburger ${
                  location.pathname === '/about' ||
                  location.pathname === '/about/' ||
                  (location.pathname.split('/').length > 2 &&
                    location.pathname.split('/')[1] === 'case-studies' &&
                    location.pathname.split('/')[2] !== '')
                    ? 'hamburger--white'
                    : ''
                }`}
                onClick={() => {
                  toggleNav()
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`mobile-menu${
            navIsOpen === true ? ' mobile-menu--is-open' : ''
          }`}
        >
          <div className="fixed lg:hidden top-0 left-0 w-full bg-blue py-5 lg:my-11 lg:mx-0">
            <div className="wrapper">
              <div className="flex justify-between items-center text-white">
                <Link to="/">
                  <Logomark
                    toggleNav={toggleNav}
                    className="block lg:hidden w-8 p-px"
                  />
                </Link>

                <button
                  className={`lg:hidden hamburger${
                    navIsOpen === true ? ' hamburger--is-open' : ''
                  }`}
                  onClick={() => {
                    toggleNav()
                  }}
                />
              </div>
            </div>
          </div>

          <Menu
            toggleNav={toggleNav}
            icon="white"
            className="mt-32 block lg:hidden text-white"
          />
        </div>
      </nav>
    )
  }
}

export default Header
