import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

import DownChevron from '../SVG/DownChevron'

class MenuItem extends React.Component {
  render() {
    const {
      submenuIsOpen,
      icon,
      title,
      link,
      data,
      location,
      toggleNav,
      toggleSubmenu,
    } = this.props

    if (data) {
      var submenu = data.allPrismicServices.edges
      return (
        <div className="menu-item menu-item--has-submenu">
          <button
            onClick={toggleSubmenu}
            className="menu-item__link focus:outline-none leading-none"
          >
            {title}
            <DownChevron />
          </button>
          <div className={`submenu ${submenuIsOpen ? 'submenu--is-open' : ''}`}>
            <ul className="lg:flex lg:-ml-10 -mb-8 lg:mb-0">
              {submenu
                .filter(item => item.node.data.heading !== null)
                .map((item, index) => {
                  let services = item.node.data.services;
                  return (
                    <li
                      className="lg:pl-10 pb-8 lg:pb-0"
                      key={index}
                    >
                      <Link
                        className="uppercase text-xxs inline-block text-white lg:text-blue lg:block font-bold tracking-wider lg:pr-10 hover:opacity-75 focus:opacity-75 transition-opacity mb-2 lg:mb-6"
                        onClick={toggleNav}
                        to={item.node.uid}
                      >
                        <div>{item.node.data.heading}</div>
                      </Link>
                      <ul>
                        {services
                          .filter(item => item.service_item.document !== null)
                          .map((item, index) => {
                            let serviceItem = item.service_item.document;
                            return (
                              <li key={index} className="mt-4 leading-none">
                                <Link
                                  onClick={toggleNav}
                                  activeClassName="opacity-75"
                                  to={`${serviceItem.data.category.uid}/${serviceItem.uid}`} className="link inline-block">
                                  <div className="flex items-center">
                                    <div className="h-8 w-8 lg:w-10 mr-4 items-center justify-center flex">
                                      {icon && icon === 'white' ? (
                                        <img
                                          src={serviceItem.data.icon_white.url}
                                          alt={serviceItem.data.icon_white.alt}
                                        />
                                      ) : (
                                          <img
                                            src={serviceItem.data.icon.url}
                                            alt={serviceItem.data.icon.alt}
                                          />
                                        )}
                                    </div>
                                    <div className="font-semibold lg:text-blue">{serviceItem.data.title}</div>
                                  </div>
                                </Link>
                              </li>
                            )
                          })}
                      </ul>
                    </li>
                  )
                })}
            </ul>
          </div>
        </div>
      )
    }
    return (
      <div className="menu-item">
        <Link
          className={`menu-item__link${location === '/about' ||
            location === '/about/' ||
            (location !== undefined &&
              location.split('/').length > 2 &&
              location.split('/')[1] === 'case-studies' &&
              location.split('/')[2] !== '')
            ? ' menu-item__link--white'
            : ''
            }`}
          activeClassName="menu-item__link--active"
          onClick={toggleNav}
          to={link}
        >
          {title}
        </Link>
      </div>
    )
  }
}

class Menu extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      submenuIsOpen: false,
    }
  }

  toggleSubmenu = () => {
    this.setState({
      submenuIsOpen: !this.state.submenuIsOpen,
    })
  }

  render() {
    const { icon, className, toggleNav, location } = this.props

    return (
      <StaticQuery
        query={graphql`
          query allPrismicServices {
            allPrismicServices(
              sort: { fields: data___menu_order, order: ASC }
            ) {
              edges {
                node {
                  uid
                  data {
                    heading
                    services {
                      service_item {
                        document {
                          ... on PrismicServicesItems {
                            uid
                            data {
                              title
                              icon {
                                url
                                alt
                              }
                              icon_white {
                                url
                                alt
                              }
                              category {
                                uid
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => {
          return (
            <div className={`${className}`}>
              <MenuItem
                submenuIsOpen={this.state.submenuIsOpen}
                toggleSubmenu={this.toggleSubmenu}
                toggleNav={toggleNav}
                location={location}
                title="Services"
                link="/services"
                icon={icon}
                data={data}
              />
              <MenuItem
                toggleNav={toggleNav}
                location={location}
                title="About"
                link="/about"
              />
              <MenuItem
                toggleNav={toggleNav}
                location={location}
                title="Case Studies"
                link="/case-studies"
              />
              <MenuItem
                toggleNav={toggleNav}
                location={location}
                title="Contact"
                link="/contact"
              />
            </div>
          )
        }}
      />
    )
  }
}

export default Menu
