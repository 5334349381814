import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Primary from '../Footer/Primary'
import Secondary from '../Footer/Secondary'
import Tertiary from '../Footer/Tertiary'

const Footer = ({ location }) => (
  <StaticQuery
    query={graphql`
      query allPrismicFooter {
        allPrismicServices(sort: { fields: data___menu_order, order: ASC }) {
          edges {
            node {
              uid
              data {
                heading
                icon {
                  alt
                  url
                }
              }
            }
          }
        }
        prismicGlobal {
          data {
            heading
            address {
              html
              text
            }
          }
        }
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      var site = data.site.siteMetadata
      var services = data.allPrismicServices.edges
      var global = data.prismicGlobal.data
      return (
        <footer className="bg-blue text-white">
          <div className="wrapper pt-24 pb-8 lg:pb-20">
            {location.pathname.split('/')[1] !== 'services' && (
              <Primary services={services} />
            )}

            <Secondary global={global} />
            <Tertiary site={site} />
          </div>
        </footer>
      )
    }}
  />
)

export default Footer
