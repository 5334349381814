import React from 'react'

const DownChevron = ({ className }) => {
  return (
    <svg
      className={`button__svg stroke-current inline-block${
        className !== undefined ? ' ' + className : ' ml-2'
      }`}
      width="15"
      height="9"
      viewBox="0 0 15 9"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 1L7.59 7 1.194 1" strokeWidth="2" fill="none" />
    </svg>
  )
}

export default DownChevron
