import React from 'react'

const Logomark = ({ className, toggleNav }) => (
  <svg
    onClick={toggleNav}
    className={`${className} fill-current`}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 42 60"
  >
    <path d="M20.9 41.2c-.5 0-1 0-1.5-.1l9.1-15.9c.4-.7 0-1.3-.8-1.3h-3c-.8 0-1.1-.6-.8-1.3l4.6-8.3c4.1 2.6 6.8 7.1 6.8 12.3.1 8.1-6.4 14.6-14.4 14.6M6.5 26.7c0-7.5 5.6-13.7 12.9-14.4l-6.6 16.1c-.3.8.1 1.4.9 1.4h3.9c.8 0 1.2.6 1 1.4l-3.1 9.1c-5.3-2.3-9-7.5-9-13.6m25.3-18l3.8-6.8c.4-.7.1-1.3-.8-1.3h-9.2c-.8 0-1.7.6-2 1.4l-1.5 3.7H21c-11.6-.1-21 9.4-21 21 0 8.9 5.5 16.6 13.3 19.6L9 58.9c-.3.8-.2.8.2.1L16 47.2c1.6.4 3.3.6 5 .6 11.5 0 20.9-9.5 20.9-21.1 0-7.6-4.1-14.3-10.1-18" />
  </svg>
)

export default Logomark
