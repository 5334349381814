import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import { Link } from 'gatsby'

const MenuItem = ({ title, link }) => {
  return (
    <div className="mb-4 lg:mb-0 lg:pl-12">
      <Link
        className="transition-opacity hover:opacity-75 focus:opacity-75"
        to={link}
      >
        {title}
      </Link>
    </div>
  )
}

class Menu extends React.Component {
  render() {
    return (
      <StaticQuery
        query={graphql`
          query allPrismicService {
            allPrismicServices(
              sort: { fields: data___menu_order, order: ASC }
            ) {
              edges {
                node {
                  uid
                  data {
                    heading
                  }
                }
              }
            }
          }
        `}
        render={data => {
          var submenu = data.allPrismicServices.edges
          return (
            <div className="mb-4 lg:mb-0 lg:flex lg:order-2">
              {submenu.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    title={item.node.data.heading}
                    link={`/${item.node.uid}`}
                  />
                )
              })}
              <MenuItem title="About" link="/about" />
              <MenuItem title="Case Studies" link="/case-studies" />
              <MenuItem title="Contact" link="/contact" />
            </div>
          )
        }}
      />
    )
  }
}

export default Menu
